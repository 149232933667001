import { render, staticRenderFns } from "./AttractionStatisticsIssuedTickets.vue?vue&type=template&id=1dd60e98&scoped=true&"
import script from "./AttractionStatisticsIssuedTickets.vue?vue&type=script&lang=ts&"
export * from "./AttractionStatisticsIssuedTickets.vue?vue&type=script&lang=ts&"
import style0 from "./AttractionStatisticsIssuedTickets.vue?vue&type=style&index=0&id=1dd60e98&prod&lang=scss&scoped=true&"
import style1 from "./AttractionStatisticsIssuedTickets.vue?vue&type=style&index=1&id=1dd60e98&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1dd60e98",
  null
  
)

export default component.exports